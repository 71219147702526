<template>
  <v-sheet outlined elevation="1" width="100%">
    <h2 class="d-flex flex-row">
      <v-icon
        :ripple="false"
        class="section-header-icon"
        v-if="isCollapsable"
        @click="toggleShow"
      >
        {{ show ? "mdi-chevron-down" : "mdi-chevron-right" }}</v-icon
      >
      <slot></slot>
    </h2>
    <slot name="extra"></slot>
  </v-sheet>
</template>

<script>
export default {
  props: {
    isCollapsable: {
      type: Boolean,
      require: false,
      default: true,
    },
    target: {
      type: Function,
      require: false,
      default: () => "",
    },
    details: {},
  },
  data() {
    return {
      show: true,
    };
  },
  name: "SectionHeader",
  methods: {
    toggleShow() {
      document.activeElement.blur();
      this.show = !this.show;
      this.$root.$emit("toggleSectionBody", {
        target: this.target(),
        showValue: this.show,
      });
    },
  },
};
</script>

<style scoped>
.theme--light.v-sheet {
  color: #001571;
  padding: 12px;
}

.section-header-icon {
  color: #001571 !important;
  font-weight: bold !important;
  font-size: 1.5em !important;
}
</style>
