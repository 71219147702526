import _ from "lodash";

export function humanize(str) {
  return capitalize(
    _.trim(_.snakeCase(str).replace(/_id$/, "").replace(/_/g, " "))
  );
}

export function capitalize(str) {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const newStr = arr.join(" ");

  return newStr;
}
