var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"headers":_vm.headers,"footer-props":_vm.footerProps,"items":_vm.screeners,"loading":_vm.isLoading,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function({ item }){return [_c('router-link',{staticClass:"view-link",attrs:{"to":{
        name: 'ScreenerView',
        params: { screenerId: item.id },
      }}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.attributes.title",fn:function({ item }){return [_c('router-link',{staticClass:"view-link",attrs:{"to":{
        name: 'ScreenerView',
        params: { screenerId: item.id },
      }}},[_vm._v(" "+_vm._s(item.attributes.title)+" ")])]}},{key:"item.attributes.isActive",fn:function({ item }){return [(item.attributes.isActive)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-cancel")])]}},{key:"item.engagements",fn:function({ item }){return _vm._l((item.engagements),function(engagement){return _c('span',{key:engagement.id},[_vm._v(" "+_vm._s(engagement.attributes.name)+" "),_c('br')])})}},{key:"no-data",fn:function(){return [_c('no-data-alert')]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }