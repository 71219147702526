<template>
  <v-card-text>
    <div v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else>
      <v-snackbar
        v-model="snackbar"
        :multi-line="true"
        color="warning"
        timeout="-1"
        outlined
        absolute
        bottom
        right
        transition="slide-x-reverse-transition"
      >
        Engagements must be deleted before a sponsor is deleted.

        <template v-slot:action="{ attrs }">
          <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            <span class="d-none" aria-hidden="true">Close</span>
          </v-btn>
        </template>
      </v-snackbar>
      <v-card class="mb-12">
        <v-card-text>
          <dl>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="4">
                  <dt>Sponsor ID:</dt>
                  <dd v-text="identification"></dd>
                </v-col>
                <v-col cols="12" sm="4">
                  <dt>Business Practice:</dt>
                  <dd v-text="business_practice"></dd>
                </v-col>
                <v-col cols="12" sm="4">
                  <dt>Country:</dt>
                  <dd v-text="country_name"></dd>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <dt>Created Date:</dt>
                  <dd v-text="created"></dd>
                </v-col>
                <v-col cols="12" sm="4">
                  <dt>Status:</dt>
                  <dd>
                    <span>
                      <v-icon v-if="is_active" color="green"
                        >mdi-check-circle</v-icon
                      >
                      <v-icon v-else color="red">mdi-cancel</v-icon>
                      {{ is_active ? "Active" : "Inactive" }}
                    </span>
                  </dd>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <hr />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <dt>Total Referrals:</dt>
                  <dd v-text="totalReferrals"></dd>
                </v-col>
                <v-col cols="12" sm="4">
                  <dt>Total Screened Referrals</dt>
                  <dd v-text="totalScreenedReferrals"></dd>
                </v-col>
                <v-col cols="12" sm="4">
                  <dt>Total Randomized Referrals</dt>
                  <dd v-text="totalRandomizedReferrals"></dd>
                </v-col>
              </v-row>
            </v-container>
          </dl>
        </v-card-text>
      </v-card>
      <v-card class="mb-12">
        <section-header :target="() => this.$refs.engagementsContainer">
          <v-container>
            <v-row>
              <v-col cols="12" md="4"> Engagements </v-col>
              <v-spacer></v-spacer>
              <v-btn
                v-if="showEngagementAddButton"
                :to="{
                  name: 'EngagementAdd',
                  params: { selectedSponsorId: id },
                }"
                color="primary"
                class="mt-1"
              >
                Add Engagement
                <v-icon aria-label="Add Icon"> mdi-plus </v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </section-header>
        <v-card-text>
          <v-container fluid ref="engagementsContainer">
            <engagements-table
              :included="included"
              :engagements="sponsorEngagements"
              :engagementsCount="engagementsCount"
              :isLoading="isLoading"
            >
            </engagements-table>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card>
        <section-header :target="() => this.$refs.screenersContainer">
          <v-container>
            <v-row>
              <v-col cols="12" md="4"> Screeners </v-col>
              <v-spacer></v-spacer>
              <v-btn
                v-if="showScreenersAddButton"
                :to="{
                  name: 'ScreenerAdd',
                  params: { selectedSponsorId: id },
                }"
                color="primary"
                class="mt-1"
              >
                Add Screener
                <v-icon aria-label="Add Icon"> mdi-plus </v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </section-header>
        <v-card-text>
          <v-container fluid ref="screenersContainer">
            <screeners-table
                :sponsor-id="id"
            >
            </screeners-table>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
  </v-card-text>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters, mapState } from "vuex";
import EngagementsTable from "@admin/components/EngagementsTable.vue";
import ScreenersTable from "@admin/components/screeners/Table";
import SectionHeader from "@admin/components/SectionHeader.vue";

export default {
  name: "SponsorsView",
  components: {
    EngagementsTable,
    ScreenersTable,
    SectionHeader,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapFields("sponsors", {
      business_practice: "sponsor.business_practice",
      country_name: "sponsor.country_name",
      identification: "sponsor.identification",
      is_active: "sponsor.is_active",
      created: "sponsor.formattedCreateDate",
      totalReferrals: "sponsor.totalReferrals",
      totalScreenedReferrals: "sponsor.totalScreenedReferrals",
      totalRandomizedReferrals: "sponsor.totalRandomizedReferrals",
    }),
    ...mapState("sponsors", [
      "sponsor",
      "engagementsCount",
      "error",
      "included",
    ]),
    title() {
      return "View Sponsor";
    },
    showEngagementAddButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
    showScreenersAddButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
    showSponsorsEditButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
    showSponsorsDeleteButton() {
      return this.acceptRoles(["Admin", "Super User"]);
    },
    ...mapGetters("sponsors", ["sponsorEngagements"]),
    ...mapGetters(["acceptRoles"]),
  },
  data() {
    return {
      isLoading: false,
      snackbar: false,
    };
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    ...mapActions("sponsors", ["getSponsor", "clearSponsor", "deleteSponsor"]),
    // delete sponsor
    remove() {
      this.deleteSponsor(this.id).then(([success]) => {
        if (success) {
          this.$router.push({ name: "SponsorsIndex", params: {} });
        } else {
          this.snackbar = true;
        }
      });
    },
  },
  async beforeMount() {
    this.isLoading = true;
    await this.getSponsor(this.id).catch(() => {
      this.$router.push({ name: "NotFound" });
    });
    this.isLoading = false;
    this.setBreadcrumbs();
    this.setTitleObject(this.name);
    let actionButtons = [];
    if (this.showSponsorsDeleteButton) {
      actionButtons.push({
        attributes: {
          onConfirm: () => this.remove(),
        },
        component: "DeleteButton",
        index: 0,
      });
    }
    if (this.showSponsorsEditButton) {
      actionButtons.push({
        attributes: {
          to: "/sponsors/edit/" + this.id,
        },
        component: "EditButton",
        index: 1,
      });
    }
    this.setActionButtons(actionButtons);
  },
  destroyed() {
    this.clearSponsor();
    this.clearActionButtons();
    this.clearTitleObject();
  },
};
</script>

<style scoped>
dt {
  font-weight: bold;
  font-size: 1.1rem;
}
</style>
