<template>
  <v-data-table
    :headers="headers"
    :footer-props="footerProps"
    :items="screeners"
    :loading="isLoading"
    :options.sync="options"
  >
    <template v-slot:item.id="{ item }">
      <router-link
        class="view-link"
        :to="{
          name: 'ScreenerView',
          params: { screenerId: item.id },
        }"
      >
        {{ item.id }}
      </router-link>
    </template>
    <template v-slot:item.attributes.title="{ item }">
      <router-link
        class="view-link"
        :to="{
          name: 'ScreenerView',
          params: { screenerId: item.id },
        }"
      >
        {{ item.attributes.title }}
      </router-link>
    </template>
    <template v-slot:item.attributes.isActive="{ item }">
      <v-icon v-if="item.attributes.isActive" color="green"
        >mdi-check-circle</v-icon
      >
      <v-icon v-else color="red">mdi-cancel</v-icon>
    </template>
    <template v-slot:item.engagements="{ item }">
      <span v-for="engagement in item.engagements" :key="engagement.id">
        {{ engagement.attributes.name }}
        <br />
      </span>
    </template>

    <template v-slot:no-data>
      <no-data-alert />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from "vuex";
import NoDataAlert from "@admin/components/NoDataAlert.vue";
import _ from "lodash";
import { humanize } from "ora-utils/src/filters/stringHelpers";

export default {
  name: "ScreenersTable",
  filters: { humanize },
  components: {
    NoDataAlert,
  },
  computed: {
    ...mapState("screeners", ["screeners", "screenersCount"]),
    ...mapState(["footerProps"]),
    pageAndSortData() {
      const data = (({ page, itemsPerPage, sortBy }) => ({
        page,
        limit: itemsPerPage,
        sortBy,
      }))(this.options);

      if (this.options.sortBy.length) {
        data.sortBy = [];
        for (let i = 0; i < this.options.sortBy.length; i++) {
          const sortField = this.options.sortBy[i];
          const desc = this.options.sortDesc[i] === true ? "-" : "";
          data.sortBy.push(desc + sortField);
        }
      }

      return data;
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: "Name",
          value: "attributes.title",
          sortable: true,
        },
        {
          text: "UUID",
          value: "id",
          sortable: false,
        },
        {
          text: "Engagements",
          value: "engagements",
          sortable: false,
        },
        {
          text: "Status",
          value: "attributes.isActive",
          sortable: true,
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ["isActive", "title"],
        sortDesc: [],
      },
    };
  },
  methods: {
    ...mapActions("screeners", ["getScreeners", "clearScreeners"]),
    async handleScreenersUpdate(options) {
      this.isLoading = true;
      options.sponsorId = this.sponsorId;
      await this.getScreeners(options);
      this.isLoading = false;
    },
  },
  props: {
    sponsorId: {
      required: true,
      type: String,
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.screeners === undefined || this.screeners.length === 0) {
      await this.getScreeners({
        sponsorId: this.sponsorId,
        include: ["engagements"],
      });
    }
    this.isLoading = false;
  },
  watch: {
    pageAndSortData: {
      async handler(currentValue, oldValue) {
        if (_.isEqual(currentValue, oldValue)) return;
        if (
          this.screeners.length < this.screenersCount &&
          currentValue.limit >= this.screeners.length
        ) {
          await this.handleScreenersUpdate(currentValue);
        }
      },
      deep: true,
    },
  },
  destroyed() {
    this.clearScreeners();
  },
};
</script>
