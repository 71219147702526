<template>
  <!-- eslint-disable -->
  <!--
    A Component that is intended to be used with a `v-table` element to override the default no-data template.
    This component was created to resolve a WCAG alert message about the potential need of a caption tag.

    @see modules/admin/src/components/EngagementsTable.vue:68
  -->
  <v-alert :value="true" color="grey lighten-2" icon="mdi-alert">
    <caption class="d-inline"><b>Sorry, nothing to display here.</b></caption>
  </v-alert>
</template>

<script>
export default {
  name: "NoDataAlert",
};
</script>

<style scoped></style>
