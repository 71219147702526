<template>
  <v-data-table
    :headers="headers"
    :footer-props="footerProps"
    :items="engagements"
    :loading="isLoading"
    :server-items-length="engagementsCount"
    :options.sync="options"
  >
    <template v-slot:item.sponsorName="{ item }">
      {{ item.attributes.sponsorName }}
    </template>
    <template v-slot:item.name="{ item }">
      <router-link
        class="view-link"
        :to="{ name: 'EngagementView', params: { engagementId: item.id } }"
      >
        {{ item.attributes.name }}
      </router-link>
    </template>
    <!-- TODO:  TOTAL RANDOMIZED REFFERALS -->
    <template> </template>
    <!-- END PLACEHOLDER -->
    <template v-slot:item.indications="{ item }">
      <v-tooltip
        v-if="getIndicationsString(item.relationships).length > 25"
        bottom
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">{{
            getIndicationsString(item.relationships) | limitStr(25)
          }}</span>
        </template>
        <span>{{ getIndicationsString(item.relationships) }}</span>
      </v-tooltip>
      <span v-else>{{ getIndicationsString(item.relationships) }}</span>
    </template>

    <template v-slot:item.phase="{ item }">
      {{ item.attributes.phase.join(", ") }}
    </template>
    <template v-slot:item.targetGroups="{ item }">
      {{ getTargetGroupsString(item.relationships) }}
    </template>
    <template v-slot:item.siteLocationsCount="{ item }">
      {{ item.attributes.siteLocationsCount }}
    </template>
    <template v-slot:item.mediaLiveDate="{ item }">
      {{ item.attributes.mediaLiveDate }}
    </template>
    <template v-slot:item.mediaEndDate="{ item }">
      {{ item.attributes.mediaEndDate }}
    </template>
    <template v-slot:item.rareDisease="{ item }">
      {{ item.attributes.rareDisease ? "Yes" : "No" }}
    </template>

    <template v-slot:item.targetParticipantCount="{ item }">
      {{ item.attributes.targetParticipantCount }}
    </template>
    <template v-slot:item.targetParticipantCompletion>
      <!-- TODO: Update to display value from API, when available
      {{ item.attributes.targetParticipantCompletion }}
      -->
      -- ToDo --
    </template>
    <template v-slot:item.startDate="{ item }">
      {{ formatDate(item.attributes.startDate) }}
    </template>
    <template v-slot:item.endDate="{ item }">
      {{ formatDate(item.attributes.endDate) }}
    </template>
    <template v-slot:item.isActive="{ item }">
      <v-icon v-if="item.attributes.isActive" color="green"
        >mdi-check-circle</v-icon
      >
      <v-icon v-else color="red">mdi-cancel</v-icon>
    </template>
    <template v-slot:no-data>
      <no-data-alert />
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
import { getBelongsToManyByType } from "@shared/util/Relationships";
import { map } from "lodash";
import NoDataAlert from "@admin/components/NoDataAlert.vue";
import _ from "lodash";

export default {
  name: "EngagementsTable",
  components: {
    NoDataAlert,
  },
  computed: {
    ...mapState("sponsors", ["sponsor"]),
    ...mapState(["footerProps"]),
    pageAndSortData() {
      const data = (({ page, itemsPerPage, sortBy }) => ({
        page,
        limit: itemsPerPage,
        sortBy,
      }))(this.options);

      if (this.options.sortBy.length) {
        data.sortBy = [];
        for (let i = 0; i < this.options.sortBy.length; i++) {
          const sortField = this.options.sortBy[i];
          const desc = this.options.sortDesc[i] === true ? "-" : "";
          data.sortBy.push(desc + sortField);
        }
      }

      return data;
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Sponsor",
          value: "sponsorName",
        },
        {
          text: "Engagement Name",
          value: "name",
        },
        {
          text: "# of Total Randomized Referrals",
          value: "totalRandomizedReferrals",
          sortable: false,
        },
        {
          text: "Indications",
          value: "indications",
          sortable: false,
        },
        {
          text: "Phase",
          value: "phase",
        },
        {
          text: "Population",
          value: "targetGroups",
          sortable: false,
        },
        {
          text: "Sites",
          value: "siteLocationsCount",
          align: "center",
          sortable: false,
        },
        {
          text: "Media Start Date",
          value: "mediaLiveDate",
        },
        {
          text: "Media End Date",
          value: "mediaEndDate",
        },
        {
          text: "Status",
          value: "isActive",
          align: "center",
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
      },
    };
  },
  filters: {
    limitStr(value, limit) {
      if (!value) return "";
      value = value.toString();

      if (value.length <= limit) {
        return value;
      }

      return value.substr(0, limit) + "...";
    },
  },
  methods: {
    getTargetGroupsString(relationships) {
      return map(
        getBelongsToManyByType(relationships, "targetGroups", this.included),
        (obj) => obj.attributes?.name
      ).join(", ");
    },
    getTherapeuticAreasString(relationships) {
      return map(
        getBelongsToManyByType(
          relationships,
          "therapeuticAreas",
          this.included
        ),
        (obj) => obj.attributes?.name
      )
        .sort()
        .join(", ");
    },
    getIndicationsString(relationships) {
      return map(
        getBelongsToManyByType(relationships, "indications", this.included),
        (obj) => obj.attributes?.name
      )
        .sort()
        .join(", ");
    },
  },
  props: {
    isLoading: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    engagements: {
      type: Array,
      required: true,
      default: () => [],
    },
    engagementsCount: {
      type: Number,
      required: true,
      default: () => 0,
    },
    included: {
      type: Array || Object,
      required: true,
      default: () => [],
    },
  },
  watch: {
    pageAndSortData: {
      async handler(currentValue, oldValue) {
        if (_.isEqual(currentValue, oldValue)) return;

        this.$emit("updateEngagements", currentValue);
      },
      deep: true,
    },
  },
};
</script>
